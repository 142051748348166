.Header{
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    .row{
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        width: 100%;
    }

}