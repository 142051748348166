.wrapper {
  padding: 10px;
  background-color: var(--color-elemBg);
  height: 250px;
  position: absolute;
  left: -310px;
  bottom: -255px;
  z-index: 50;
  border: 1px solid var(--color-border);
  border-radius: 5px;
  & .drop {
    background: var(--color-mainBg);
    color: #ffffff;
    width: 300px;
    height: 190px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
    border: 1px solid var(--color-border);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 5px;
    user-select: none;
    & .item {
      background-color: var(--color-elemBg);
      border-radius: 10px;
      padding: 10px;
      cursor: pointer;
      & .itemTitle {
        overflow-x: hidden;
        text-overflow: ellipsis;
      }
      & .footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: bold;
        font-size: var(--font-size--small);
        & .itemDate {
          color: var(--color-blue);
        }
        & .isNew {
          color: var(--color-white);
          padding: 0 5px;
          background: var(--color-green);
          border-radius: 20px;
        }
      }
    }

    & .empty {
      padding: 20px;
      color: white;
      font-weight: bold;
    }
  }

  & .drop::-webkit-scrollbar {
    width: 2px;
  }

  & .drop::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px var(--color-border);
    border-radius: 10px;
  }

  & .drop::-webkit-scrollbar-thumb {
    background: var(--color-blue);
    border-radius: 10px;
  }
  & .footer {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    & .btn {
      background-color: var(--color-green);
      border-radius: 5px;
      padding: 0 10px;
      cursor: pointer;
      border: none;
    }
    & .btn:hover {
      transform: scale(1.02);
    }
    & .btn[disabled] {
      opacity: 0.5;
    }
    & .btn[disabled]:hover {
      transform: scale(1);
      cursor: not-allowed;
    }
  }
}
