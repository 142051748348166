.customSelect {
  min-width: 120px;

  & .ant-select-selector {
    color: white;
    background-color: var(--color-mainBg) !important;
    border: 1px solid var(--color-border) !important;
  }
  & .ant-select-arrow {
    color: white;
  }
  & .ant-select-clear {
    color: white;
    background-color: var(--color-mainBg) !important;
  }
}
