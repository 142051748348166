.wrapper{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 5px;
    width: 100%;
    & .label{
        font-size: var(--font-size);
    }
    & .SliderUi{
        width: 100%;
    }
}