.container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 150px;
  & .title {
    font-weight: bold;
    font-size: var(--font-size-big);
    color: var(--color-blue);
  }
  & .subTitle {
    text-align: center;
    font-size: var(--font-size--small);
    color: var(--color-blue);
    & p {
      text-align: left;
      font-size: var(--font-size--small);
      color: var(--color-blue);
      line-height: var(--font-size-big);
    }
  }
}

.transaction {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  & .title {
    font-weight: bold;
    font-size: var(--font-size-big);
    color: var(--color-blue);
  }
  & .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    & .subTitle {
      font-weight: bold;
    }
    & .wallet {
      margin: 10px 0;
      font-size: var(--font-size-big);
      color: var(--color-blue);
      cursor: pointer;
    }
  }
  & .qr {
    height: auto;
    margin: 0 auto;
    max-width: 150px;
    width: 100%;
  }
}

.addWrapper {
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: center;
  & .input {
    width: 150px;
  }
}

.errorWrapper {
  font-size: var(--font-size-big);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 30px;
  color: var(--color-red);
  & .reload {
    color: var(--color-green);
    font-size: 48px;
  }
}
