.container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
  & .weekSelect {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  & .pageName {
    font-size: var(--font-size--header);
    font-weight: bold;
  }
  & .title {
    font-size: var(--font-size--header);
    display: flex;
    align-items: center;
    justify-content: flex-end;
    & .count {
      font-weight: bold;
    }
  }
}
