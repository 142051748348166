.View {
  flex-shrink: 0;
  border: 1px solid black;
  background: white;
  width: var(--doc-gen-preview-width);
  height: var(--doc-gen-preview-height);
  & .constr {
    position: relative;
  }
}
