.Draggable {
  cursor: pointer;
  position: absolute;
  border: 1px solid rgba(158, 158, 240, 0.4);
  padding: 5px 10px;
  color: black;
  & p {
    color: black;
  }

  &.active {
    background: rgba(158, 158, 240, 0.2);
  }
}

.img {
  color: black;
  width: 40px;
  cursor: pointer;
  position: absolute;
  border: 1px solid rgba(158, 158, 240, 0.4);
  padding: 5px 10px;
  &.active {
    background: rgba(158, 158, 240, 0.2);
  }
}
