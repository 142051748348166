.container {
  padding-top: 10px;
  & .header {
    position: relative;
    display: flex;
    font-weight: bold;
    font-size: var(--font-size--header);
    padding-bottom: 10px;
    margin-bottom: 20px;
    padding-left: 15px;
    border-bottom: 1px dashed var(--color-border-title);

    &::after {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      top: calc(50% - 9px);
      left: 0;
      border-radius: 50%;
      animation: flicker 1.5s infinite alternate;
    }
  }
  & .title {
    font-size: 20px;
    & span {
      color: var(--color-blue);
    }
  }
  & .actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }
}
