.container {
  position: fixed;
  width: 100vw;
  z-index: 10;
  background: var(--color-elemBg);
  padding: 0 var(--padding-page);
  & .top {
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    & .status {
      margin-left: 50px;
      font-size: 75%;
      font-weight: bold;
      background-color: var(--color-green);
      padding: 3px 8px;
      border-radius: 3px;
    }
    & .subscription {
      margin-left: 50px;
      font-size: 75%;
      font-weight: bold;
      background-color: var(--color-red);
      padding: 3px 8px;
      border-radius: 3px;
    }
    & .menu {
      margin-left: auto;
      display: flex;
      align-items: center;
      gap: 30px;
      & .dropdown {
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 10px;
      }
    }
  }
  & .bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    & .tabs {
      width: 100%;
    }
    & .input {
      max-width: 300px;
    }
  }
}

@media screen and (max-width: 1060px) {
  .container {
    padding: 0 20px;
    & .bottom {
      flex-direction: column-reverse;
    }
  }
}
@media screen and (max-width: 768px) {
  .container {
    & .top {
      & .logo {
        display: none;
      }
    }
  }
}
