.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  & .wrapper {
    width: 450px;
    padding: 40px;
    background: var(--color-elemBg);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    -webkit-box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 5px 5px 15px 5px rgba(0, 0, 0, 0.3);
    & .avatar {
      background-color: #005cea;
      margin-bottom: 20px;
    }
    & .form {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      gap: 20px;

      //& .submit {
      //  width: 50%;
      //}
    }
  }
  & .hidden {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 150px;
    height: 150px;
  }
}
