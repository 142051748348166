.AddPermissionTemplate {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  & .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    gap: 10px;
  }
  & .actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
