.customTable {
  & tbody {
    & td {
      padding: 5px 20px;
    }
  }
  .actions {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 0 20px;
    & .del {
      color: var(--color-red);
    }
    & .edit {
      color: var(--color-blue);
    }
    & span {
      cursor: pointer;
      font-size: 20px;
    }
  }
  & .searchIcon {
    &.active {
      color: var(--color-border-focus) !important;
    }
  }
}

.fieldSearch {
  padding: 10px;
  border: none;
  border-radius: 8px;
  min-width: 250px;
  & .actionsSearch {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
