.permissionsTemplatesPage {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  & .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
