.Preview {
  background-color: #fff;
  width: var(--doc-gen-preview-width);
  height: var(--doc-gen-preview-height);
  position: fixed;
  top: 100vh;
  color: black;
  & .element {
    padding: 5px 10px;
    position: absolute;
    & p {
      color: black;
    }
  }
}
