.DocPreview{
    & .header{
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        & .name{
            font-size: var(--font-size);
            & span{
                font-size: var(--font-size--header);
                font-weight: bold;
            }
        }
    }
}