.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
  max-width: 125px;
  & .avatar {
    background: white;
    & svg {
      fill: black;
    }
  }

  & .title {
  }
}
