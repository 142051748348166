.container {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  & .title {
    text-align: center;
    font-size: var(--font-size--header);
    font-weight: bold;
  }
  & .time {
    text-align: center;
  }

  & .form {
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }
  & .recall {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 10px;
  }
}
