.container {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  & .bell {
    cursor: pointer;
  }

  & .label {
    position: absolute;
    cursor: pointer;
    bottom: -5px;
    right: -7px;
    font-size: 10px;
    font-weight: bold;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--color-green);
  }
}

.bg {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: var(--color-mainBg);
  opacity: 0.35;
  cursor: pointer;
  z-index: 5;
}
