.container {
  padding-top: 10px;
  & .item {
    width: 100%;
    margin-bottom: 25px;
  }
  & .actions {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
  }
}
