.AddPermissionTemplate {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex-grow: 1;
  & .body {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    gap: 10px;
    & .userSelect {
      & select {
        min-height: 100px;
      }
    }
    .permissions {
      padding: 0 20px;
      user-select: none;
      & .row {
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
      }
    }
  }
  & .actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }
}
