.BankSelect{
    max-width: 650px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
    & .collaps{
        width: 100%;
        user-select: none;
        & .bankSelect{
            width: 100%;
            margin-bottom: 10px;
        }
    }
    & .row{
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        flex-wrap: wrap;
        & .select{
            width: 250px;
        }
    }
}