.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  & .title {
    font-size: var(--font-size--header);
    color: var(--color-blue);
  }
  & .description {
  }
  & .btn {
    align-self: center;
  }
}
