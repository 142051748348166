@import '../../node_modules/antd/dist/antd.css';
@import 'variables';
@import './antd/antd.scss';

* {
  box-sizing: border-box;
}

//tiny error message disabled
//.tox-notifications-container {
//  visibility: hidden;
//}
