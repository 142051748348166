.buttonUi,
.buttonUi.ant-btn:hover,
.buttonUi.ant-btn:focus,
.buttonUi.ant-btn:active {
  border-radius: 2px;
  color: white;
  font-size: var(--font-size);
  font-weight: bold;
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  &.fullWidth {
    width: 100%;
  }
}

.ant-btn[disabled],
.ant-btn[disabled]:hover {
  color: var(--color-grey);
  opacity: 0.5;
}

.buttonUi:hover {
  color: white;
}

.buttonUi.buttonUi_blue,
.buttonUi[disabled].buttonUi_blue {
  border-color: var(--color-blue);
  border: 1px solid var(--color-blue);
  background: var(--color-blue);
}

.buttonUi.buttonUi_red,
.buttonUi[disabled].buttonUi_red {
  border-color: var(--color-red);
  border: 1px solid var(--color-red);
  background: var(--color-red);
}

.buttonUi.buttonUi_green,
.buttonUi[disabled].buttonUi_green {
  border-color: var(--color-green);
  border: 1px solid var(--color-green);
  background: var(--color-green);
}

.buttonUi.buttonUi_yellow,
.buttonUi[disabled].buttonUi_yellow {
  border-color: var(--color-yellow);
  border: 1px solid var(--color-yellow);
  background: var(--color-yellow);
}

.buttonUi.buttonUi_grey,
.buttonUi[disabled].buttonUi_grey {
  border-color: var(--color-grey);
  border: 1px solid var(--color-grey);
  background: var(--color-grey);
}

.buttonUi.outlined,
.buttonUi[disabled].outlined {
  background: transparent;
}

.ant-btn.small {
  padding: 0 4px;
  min-height: 20px;
}
.ant-btn.middle {
  padding: 2px 6px;
  min-height: 24px;
  border-radius: 3px;
}
