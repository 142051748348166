.container {
  & .labelWrapper {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: flex-start;
    & .label {
      font-size: var(--font-size);
      margin-bottom: 3px;
      & span {
        margin-left: 3px;
        color: var(--color-red);
      }
    }
    & .error {
      color: var(--color-red);
      margin-bottom: 3px;
      font-size: var(--font-size);
    }
  }

  & .input {
    font-size: var(--font-size);
  }
}
