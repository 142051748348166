@import './table.scss';
@import './dropdown.scss';
p,
.ant-form,
body {
  color: white;
  margin-bottom: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
.ant-typography,
h5.ant-typography {
  color: white;
}

.ant-btn {
  height: auto;
  min-height: 36px;
}

//search input
.ant-input-group {
  & .ant-input-affix-wrapper {
    background-color: var(--color-mainBg);
    border-color: var(--color-mainBg);
    & .ant-input:hover,
    & .ant-input:focus {
      border-color: var(--color-mainBg) !important;
      box-shadow: none;
    }
    & input {
      color: white;
      background-color: var(--color-mainBg);
      border-color: var(--color-mainBg);
    }
  }
  & .ant-input-affix-wrapper:hover,
  & .ant-input-affix-wrapper:focus {
    background-color: var(--color-mainBg);
    border-color: var(--color-mainBg);
    box-shadow: none;
  }
  & .ant-input-group-addon {
    & .ant-input-search-button {
      color: white;
      background-color: var(--color-mainBg);
      border-radius: 0 !important;
      border: none;
    }
  }
  & svg {
    fill: white;
  }
}
.ant-input-affix-wrapper,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input,
.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless).ant-input:hover,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper,
.ant-input-affix-wrapper-status-error:not(.ant-input-affix-wrapper-disabled):not(.ant-input-affix-wrapper-borderless).ant-input-affix-wrapper:hover {
  background-color: var(--color-mainBg);
}
.ant-input-affix-wrapper {
  border-color: var(--color-border);

  & .ant-input {
    background-color: var(--color-mainBg);
  }
  & .ant-input-prefix {
    color: white;
  }
}
// Tabs
.ant-tabs {
  color: white;
  & .ant-tabs-nav {
    margin: 0;
    & .ant-tabs-ink-bar {
      background: #ec407a;
    }
    & .ant-tabs-tab {
      & .ant-tabs-tab-btn {
        font-weight: bold;
        color: white;
        & a {
          color: white;
        }
      }
    }
  }
  & .ant-tabs-nav::before {
    border-bottom: none;
  }
  & .ant-tabs-tab {
    padding: 0;
    & .ant-tabs-tab-btn {
      & .ant-dropdown-trigger,
      & a {
        padding: 12px 0;
      }
    }
  }
}

.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 0 0 20px;
}

//dropDown menu
.ant-tabs-dropdown {
  color: white;
  .ant-tabs-dropdown-menu {
    background-color: var(--color-elemBg);
    & .ant-tabs-dropdown-menu-item {
      color: white;
    }
    & .ant-tabs-dropdown {
      color: white;
    }
    & .ant-tabs-dropdown-menu-item:hover {
      background-color: var(--color-elemBg);
      opacity: 0.5;
    }
    & a {
      color: white;
    }
  }
}

.ant-select-dropdown {
  background-color: var(--color-mainBg);
  color: white;
  .ant-select-item-option,
  .ant-select-item-group,
  .ant-select-item-option-active {
    background-color: var(--color-mainBg);
    color: white;
  }
}

.anticon.anticon-down {
  margin-right: 0;
}

//SPIN
.ant-spin {
  color: white;
  font-size: 18px;
  font-weight: bold;
}

//INPUT
.ant-input,
.ant-input-affix-wrapper {
  box-sizing: border-box;
  background-color: var(--color-mainBg);
  border: 1px solid var(--color-border);
  padding: 6px 13px;
  color: white;
}

.ant-input[disabled] {
  color: white;
  background-color: var(--color-mainBg);
  border-color: var(--color-border);
  opacity: 0.6;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  background-color: var(--color-mainBg);
  transition: background-color 600000s 0s, color 600000s 0s;
}

//TABLE
.ant-table {
  background: var(--color-elemBg);
  color: white;
  font-size: var(--font-size);
  & .ant-table-content {
    overflow-x: auto;
    & .ant-table-tbody > tr > td {
      border-bottom: 1px solid var(--color-border);
    }
  }
  & .ant-table-row:hover,
  & .ant-table-cell,
  & .ant-table-cell-row-hover,
  td,
  & .ant-table-tbody > tr.ant-table-row:hover > td,
  & .ant-table-tbody > tr > td.ant-table-cell-row-hover,
  & .ant-table-tbody > tr.ant-table-placeholder:hover > td,
  & .ant-table-thead th.ant-table-column-sort,
  .ant-table-tbody > tr.ant-table-row-selected > td {
    background: var(--color-elemBg);
    color: white;
  }
  & .ant-table-thead > tr > th {
    font-weight: bold;
  }
  // удалить разделение в хедере
  &
    .ant-table-thead
    > tr
    > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    display: none;
  }
}
.ant-pagination {
  color: white;
  & .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-ellipsis,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-ellipsis,
  & .ant-pagination-jump-prev .ant-pagination-item-container .ant-pagination-item-link-icon,
  .ant-pagination-jump-next .ant-pagination-item-container .ant-pagination-item-link-icon {
    color: white;
  }
  & .ant-pagination-prev .ant-pagination-item-link,
  & .ant-pagination-next .ant-pagination-item-link,
  & .ant-pagination-item,
  & .ant-pagination-item-link,
  & .ant-select-selector,
  & .ant-select,
  & .ant-select:not(.ant-select-customize-input) .ant-select-selector,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: var(--color-elemBg);
    color: white;
    border-color: white;
    & a {
      color: white;
    }
    & .ant-select-arrow {
      color: white;
    }
  }
  & .ant-pagination-item-active {
    font-weight: bold;
    border: 2px solid white;
  }
  & .ant-pagination-item:hover,
  & .ant-pagination-prev:hover,
  & .ant-pagination-next:hover,
  & .ant-pagination-disabled {
    opacity: 0.3;
    border-color: white;
  }
  //.ant-pagination-prev:hover .ant-pagination-item-link{
  //  border-color: white;
  //  opacity: 0.3;
  //}
}

//EMPTY
.ant-empty,
.ant-empty-normal {
  color: white;
}

//MODAL
.ant-modal-content {
  background: var(--color-elemBg);
  color: white;
  min-width: 520px;
  max-width: inherit;
  border-radius: 10px;
  & .ant-modal-close {
    color: white;
  }
}

//SELECT
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple.ant-select-disabled.ant-select:not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: var(--color-mainBg);
  color: white;
  opacity: 0.6;
}
.ant-select-status-error.ant-select:not(.ant-select-disabled):not(.ant-select-customize-input)
  .ant-select-selector {
  background-color: var(--color-mainBg);
}
.ant-select:not(.ant-select-customize-input) {
  & .ant-select-clear {
    background-color: var(--color-mainBg);
    color: white;
  }
  & .ant-select-selector {
    background-color: var(--color-mainBg);
    color: white;
    border: 1px solid var(--color-border);
    min-height: 36px;
    & .ant-select-selection-overflow-item {
      & span {
        background: var(--color-elemBg);
        border: 1px solid var(--color-elemBg);
        & .anticon-close {
          color: white;
        }
      }
    }
  }
}
.ant-select-arrow {
  color: white;
}

//PROGRESS
.ant-progress-circle .ant-progress-text {
  color: white;
}

//FORM
.ant-form-item {
  margin: 0;
  & .ant-form-item-control-input {
    min-height: 0;
  }
}

//DATE PICKER
.ant-picker-status-error.ant-picker,
.ant-picker-status-error.ant-picker:not([disabled]):hover {
  background: var(--color-mainBg);
}

.ant-picker-cell-disabled {
  opacity: 0.6;
}

.ant-picker,
.ant-picker.ant-picker-disabled {
  background: var(--color-mainBg);
  color: white;
  border-color: var(--color-border);
  min-height: 36px;
  & .ant-picker-input {
    & .ant-picker-clear {
      background: var(--color-mainBg);
    }
    & .ant-picker-clear:hover {
      color: black;
    }
  }
  & input {
    color: white;
  }
  & span {
    color: white;
    & svg {
      background: var(--color-mainBg);
      fill: white;
    }
  }
}
.ant-picker.ant-picker-disabled {
  opacity: 0.6;
}
.ant-picker-dropdown {
  color: white;
  .ant-picker-panel-container {
    background: var(--color-mainBg);
    color: white;
    border-color: var(--color-border);
    & .ant-picker-header {
      color: white;
      & button {
        color: white;
      }
    }
    & .ant-picker-footer {
      & .ant-picker-today-btn {
        font-weight: bold;
        color: white;
      }
    }
  }
  & .ant-picker-body {
    color: white;
    .ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
    .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end)
      .ant-picker-cell-inner {
      background: var(--color-grey);
    }
    & .ant-picker-cell {
      color: rgba(255, 255, 255, 0.25);
    }
    & .ant-picker-content th,
    & .ant-picker-cell-in-view,
    & .ant-picker-time-panel-cell-inner {
      color: white;
    }
  }
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before,
.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before,
.ant-picker-time-panel-column
  > li.ant-picker-time-panel-cell-selected
  .ant-picker-time-panel-cell-inner {
  background: #1890ff;
  opacity: 0.9;
}

.ant-picker-week-panel-row:hover td {
  background-color: #1890ff;
  opacity: 0.7;
}

//TIME PICKER
.ant-picker-time-panel {
  & .ant-picker-content {
    & .ant-picker-time-panel-column {
      & .ant-picker-time-panel-cell {
        & .ant-picker-time-panel-cell-inner:hover {
          color: var(--color-mainBg);
        }
        & .ant-picker-time-panel-cell-inner {
          color: white;
        }
      }
      & .ant-picker-time-panel-cell-selected {
        & .ant-picker-time-panel-cell-inner {
          color: white;
        }
      }
    }
  }
}

//DIVIDER

.ant-divider {
  border-top: 1px solid rgba(255, 255, 255, 0.3);
  margin: 10px 0;
}

//CHECKBOX

.ant-checkbox-wrapper {
  color: white;
  & .ant-checkbox-checked .ant-checkbox-inner {
    background-color: var(--color-green);
    border-color: var(--color-green);
  }
}

//Collapse
.ant-collapse {
  background-color: transparent;
  color: var(--color-white);
  border: none;
  & .ant-collapse-content {
    background-color: transparent;
    color: var(--color-white);
    border-top: 1px solid var(--color-border);
  }
}
.ant-collapse > .ant-collapse-item {
  border-bottom: 1px solid var(--color-border);
}
.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: var(--color-white);
}

//table expand
.ant-table-row-expand-icon {
  background: var(--color-elemBg);
}

tr.ant-table-expanded-row > td,
tr.ant-table-expanded-row:hover > td {
  background: var(--color-elemBg);
}
.ant-table-row-expand-icon,
.ant-table-row-expand-icon:focus,
.ant-table-row-expand-icon:hover {
  color: var(--color-white);
}
