:root {
  --color-white: white;
  --color-mainBg: #262d3c;
  --color-elemBg: #353f53;
  --color-green: #66bb6a;
  --color-grey: #686767;
  --color-border: #4e4e5a;
  --color-blue: #4fc3f7;
  --color-border-focus: #40a9ff;
  --color-red: #ef5350;
  --color-yellow: #ffb74d;
  --padding-page: 5.625rem;
  --font-size--small: 0.6rem;
  --font-size: 0.8125rem;
  --font-size--header: 0.9375rem;
  --font-size-big: 1.3rem;
  --border-radius: 6px;
  --header-height-admin: 100px;
  --header-height-manager: 50px;
  --color-violet: #984af6;
  --doc-gen-preview-width: 850px;
  --doc-gen-preview-height: 1183px;
}
