.ImgEdit{
    padding: 0 20px;
    width: 100%;
    & .label{
        margin: 5px 0;

    }
    & .typeSelect{

        width: 100%;
    }
}