.container {
  position: fixed;
  width: 100vw;
  z-index: 10;
  background: var(--color-elemBg);

  display: flex;
  flex-direction: column;
  //height: var(--header-height-manager);

  & .wrapper {
    padding: 10px var(--padding-page);
    display: flex;
    align-items: center;
    justify-content: space-between;
    & .dropdown {
      display: flex;
      gap: 40px;
      align-items: center;
      & .btnWrapper {
        position: relative;
        & .testBtn {
          cursor: pointer;
          padding: 3px 20px;
          background: var(--color-blue);
          border-radius: 5px;
          font-weight: bold;
        }
        & .tag {
          user-select: none;
          font-weight: bold;
          font-size: var(--font-size--small);
          padding: 0 5px;
          border-radius: 10px;
          background: var(--color-green);
          position: absolute;
          right: -7px;
          bottom: -7px;
        }
      }
      & .btnWrapper:hover {
        opacity: 0.9;
      }
    }
  }
}
