.container {
  font-size: var(--font-size--header);
  font-weight: bold;
  &.colored {
    color: var(--color-blue);
  }
}

.container.big {
  font-size: 20px;
}
