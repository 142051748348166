.dropContainer {
  & .ant-dropdown-menu {
    margin: 10px;
    padding: 10px 5px;
    background-color: var(--color-elemBg);
    border: 1px solid var(--color-border);
    & .ant-dropdown-menu-item-divider {
      opacity: 0.2;
    }
    & .ant-dropdown-menu-item {
      padding: 8px 12px;
      color: white;
      font-weight: bold;
      & .ant-dropdown-menu-item-icon {
        margin-right: 15px;
        font-size: 18px;
      }
    }
    & .ant-dropdown-menu-item:hover {
      background-color: var(--color-elemBg);
      opacity: 0.4;
    }
  }
}
