.container {
  position: relative;
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  .body {
    padding: 20px var(--padding-page);
    font-size: var(--font-size);
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  & .body.admin {
    padding-top: var(--header-height-admin);
  }

  & .body.manager {
    padding-top: var(--header-height-manager);
  }

  & .loadSpin {
    position: fixed;
    inset: 0;
    z-index: 10000;
    height: 100%;
    background-color: #00000073;
  }
}

@media screen and (max-width: 1276px) {
  .container {
    .body {
      padding: 20px;
    }

    & .body.admin {
      padding-top: calc(var(--header-height-admin) + 0px);
    }
  }
}

@media screen and (max-width: 1060px) {
  .container {
    & .body.admin {
      padding-top: calc(var(--header-height-admin) + 45px);
    }
  }
}

// @media screen and (max-width: 822px) {
//   .container {
//     & .body.admin {
//       padding-top: calc(var(--header-height-admin) + 45px);
//     }
//   }
// }
