.DocEditor {
  box-sizing: border-box;

  & .body {
    display: flex;
    //justify-content: space-between;
    gap: 20px;
    position: relative;
    & .editWrapper {
      border: 1px solid black;
      border-radius: 10px 0 0 10px;
      width: 550px;
      min-height: 650px;
      background: var(--color-elemBg);
      display: flex;
      flex-direction: column;
      right: 0;
      overflow: hidden;
      -webkit-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.75);
      box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.75);
    }
  }
}

@media (max-width: 1500px) {
  .DocEditor {
    & .body {
      flex-direction: column;
      & .editWrapper {
        position: fixed;
        z-index: 10;
      }
    }
  }
}

@media (max-width: 1400px) {
  .DocEditor {
    & .body {
      flex-direction: column;
      & .editWrapper {
        position: relative;
        border: none;
        box-shadow: none;
        bottom: 0;
        border-radius: 0;
      }
    }
  }
}
