.select {
  width: 100%;

  & select {
    //border: none;
    width: 100%;
    min-height: 200px;
    background-color: var(--color-mainBg);
    color: white;
    border: 1px solid var(--color-border);
    border-radius: 2px;
    & option {
      padding: 2px 10px;
    }
  }
  & select:focus-visible,
  & select:focus,
  & select:active {
    // border: none;
    outline: none;
  }
  & select option:checked,
  & select:focus option:checked:hover {
    border: none;
    background-color: var(--color-border-focus);
    color: white;
  }
}
